<template>
  <v-container id="home_menu" fluid tag="section">
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      border="left"
      colored-border
      elevation="2"
    >
      <v-row>
        <v-col cols="12" md="3"> </v-col>
        <v-col cols="12" md="3">
          <div>
            <h2>ผู้ใช้งานระบบ : {{ user.user_ID }}</h2>
          </div>
          <div>
            <h3>
              ชื่อผู้ใช้งาน : {{ user.user_firstname }}
              {{ user.user_lastname || "" }}
            </h3>
          </div>
          <h3>สถานะ : {{ user.user_status }}</h3>
        </v-col>
        <v-col cols="12" md="3"> </v-col>
      </v-row>
    </v-alert>
    <v-row>
      <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          icon="mdi-account-cog"
        >
          <v-card-title>ผู้ใช้งานระบบ</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in user_system_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
     <!--  <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          icon="mdi-account-cog"
        >
          <v-card-title>ข้อมูลสถานศึกษา</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in iven_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col> -->
      <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          icon="mdi-account-details"
        >
          <v-card-title>ข้อมูลงานบุคคล</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in user_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          type="info"
          elevation="2"
          icon="mdi-account-convert"
        >
          <v-card-title>รายงานการเสนอย้าย <h3 class="red--text pl-2">ข้าราชการครู</h3> </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in movement_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
     <!--  <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          type="grey"
          elevation="2"
          icon="mdi-account-convert"
        >
          <v-card-title
            >รายงานผู้บริหารเสนอย้าย
            <h3 class="red--text">ขอย้ายเข้า</h3>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="VideoDialogManageMove()">
              <v-icon large color="red">mdi-video</v-icon>
            </v-btn>
          </v-card-title>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in movementmanageout_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col> -->
     <!--  <v-col cols="12" md="12" v-if="periods.period_enable_process === '1'">
        <v-alert
          border="left"
          colored-border
          type="grey"
          elevation="2"
          icon="mdi-account-convert"
        >
          <v-card-title
            >รายงานผู้บริหารเสนอย้าย
            <h3 class="red--text">ขอย้ายออก</h3>
          </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in movementmanagein_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>      
     -->
      <v-col cols="12" md="12">
        <v-alert
          border="left"
          colored-border
          color="yellow accent-4"
          elevation="2"
          icon="mdi-human-male-male"
        >
          <v-card-title>ระบบครูผู้ช่วย

             <v-spacer></v-spacer>
            <v-btn text color="red" @click="VideoDialogAssessmentDev()">
              <v-icon large color="red">mdi-video</v-icon>
            </v-btn>


          </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in prepare_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
    </v-row>

    <!-- V-model userdialog -->
    <v-layout row justify-center>
      <v-dialog v-model="adduserdialog" persistent max-width="80%">
        <v-card class="mx-auto pa-6" elevation="2">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="เปลี่ยนรหัสผ่าน"
            class="px-5 py-3 text_google"
            elevation="2"
          >
          </base-material-card>
          <v-card-text>
            <v-form ref="userform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    {{ user.user_name }} {{ user.user_firstname
                    }}{{ user.user_lastname }}
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Password"
                      v-model="user.user_password"
                      type="password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Confirm Password"
                      v-model="user.user_confirmpassword"
                      type="password"
                      required
                      :rules="[v => v == user.user_password]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click.stop="adduserdialog = false" rounded>
              <v-icon dark>mdi-close</v-icon>ยกเลิก
            </v-btn>
            <v-btn large color="warning" @click.stop="userSubmit()" rounded>
              <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeManageMovement"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/Tg9DjFm6SDM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeManageMovement = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeConditionbranch"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/iVZy90tWSZg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeConditionbranch = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeAssessmentDevTeach"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                   width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/IuTKn-M-KGY"                 
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeAssessmentDevTeach = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      user: {},
      periods: [],
      adduserdialog: false,
      VideoDialogYoutubeManageMovement: false,
      VideoDialogYoutubeConditionbranch: false,
      VideoDialogYoutubeAssessmentDevTeach: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      user_system_items: [
        {
          text: "ข้อมูลผู้ใช้งานระบบ ข้าราชการครูและบุคลาการทางการศึกษา",
          icon: "mdi-account-arrow-right",
          to: "/iven/personnel"
        }
      ],

      iven_items: [
        {
          text: "ข้อมูลรายละเอียดสถานศึกษา",
          icon: "mdi-information",
          to: "/iven/iveninfo"
        }
      ],

      user_items: [
        {
          text:
            "ข้อมูลเกี่ยวกับข้าราชการครูและบุคลากรทางการศึกษา (จากส่วนกลาง)",
          icon: "mdi-account",
          to: "/iven/personnel_tem"
        },       
        {
          text: "ข้อมูลคุณวุฒิการศึกษา",
          icon: "mdi-school",
          to: "/iven/personnel_education"
        }
      ],
      movement_items: [
      {
          text: "คณะกรรมการประเมินผู้ประสงค์ขอย้าย",
          icon: "mdi-account-circle",
          to: "/iven/commiteevecpro"
        },
        {
          text: "สายการสอนและสายสนับสนุน",
          icon: "mdi-account-circle",
          to: "/iven/transference_personnel"
        }
      ],

      movementmanagein_items: [
        {
          text: "สายบริหารในสถานศึกษายืนย้าย",
          icon: "mdi-account-circle",
          to: "/iven/transference_manage"
        }
      ],

     

      

    

      prepare_items: [
        {
          text: "ข้อมูลครูผู้ช่วย",
          icon: "mdi-account-star",
          to: "/iven/assistant_teacher"
        }
      ]
    };
  },

  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = result.data;
    await this.periodQuery();
  },

  methods: {

    

      async VideoDialogAssessmentDev() {
      this.VideoDialogYoutubeAssessmentDevTeach = true;
    },


    async VideoDialogConditionB() {
      this.VideoDialogYoutubeConditionbranch = true;
    },
    async VideoDialogManageMove() {
      this.VideoDialogYoutubeManageMovement = true;
    },

    async userUpdate() {
      this.adduserdialog = true;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_enable: "1",
        period_type: "manage",
        periord_admin: "check"
      });
      this.periods = result_period.data;
    },

    async userSubmit() {
      if (this.$refs.userform.validate()) {
        this.user.ApiKey = this.ApiKey;
        let result;
        result = await this.$http.post("user.update_password.php", this.user);
        if (result.status == "200") {
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการแก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.adduserdialog = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
